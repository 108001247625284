import { faLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";

import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "./greeting.scss";

const scrollNow = () => {
  return window.scrollTo(0, 0);
};
const Greeting = ({ bestRef, item }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const scrollTimeoutIdRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    scrollTimeoutIdRef.current = setTimeout(() => {
      window.addEventListener("scroll", handleScroll);
    }, 800);

    // Clean up the event listener and clear the timeout on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeoutIdRef.current);
    };
  }, []);

  // const [greet, setGreet] = useState("");

  return (
    <>
      <ToastContainer position="bottom-center" />
      <div className=" resp-margin">
        <div className="mainHead1 bg-[#FF7A00]">
          <div
            className={`px-2 flex items-center justify-around greeting-head2`}
          >
            <div className=" flex items-center justify-start">
              <Link to="/">
                <p
                  className="text-xl font-extrabold py-5 text-white"
                  style={{
                    fontFamily: "'Lobster', sans-serif",
                    fontSize: "22px",
                  }}
                >
                  Drive In
                </p>
              </Link>
            </div>
            {window.location.href.includes("category") && (
              <p className="text-white font-bold border-2 text-xs border-white rounded-md p-1.5">
                {window.location.href.includes("category") ? item : ""}
              </p>
            )}
            <div className="text-md mt-1 text-white font-semibold flex items-center justify-center space-x-2">
              <FontAwesomeIcon icon={faLocation} size="sm" />
              <p>Palamakul</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Greeting;
