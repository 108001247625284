import React from "react";
// Import Swiper React components

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { EffectCube, Pagination, Autoplay } from "swiper/modules";

import "./testimonials.css";
import {
  Restaurant1,
  Restaurant2,
  Restaurant3,
  Restaurant4,
  Restaurant5,
  Restaurant6,
  Restaurant7,
  Restaurant8,
} from "../restaurantImages";
// import required modules

export default function App() {
  return (
    <div className="mt-5 md:px-10 py-10 px-4 bg-gray-100 ">
      {/* <h1 className="text-xl font-extrabold py-5 text-center">Our Gallery</h1> */}
      <div className="grid grid-cols-8 gap-4 mx-auto  ">
        <div className="md:col-span-4 lg:col-span-5 col-span-8  flex flex-col items-center justify-center px-2">
          <h1 className="font-extrabold text-xl ">
            Where Youthful Vibes Meet Delicious Delights
          </h1>
          <h1 className="text-justify py-5 text-gray-600 font-semibold">
            Welcome to RK Drive-In, where the pulse of youth meets the flavors
            of delight! At RK Drive-In, we understand that dining out isn't just
            about the food – it's about the experience, the vibe, and the
            memories created with every bite
          </h1>
        </div>
        <div className="md:col-span-4 lg:col-span-3 col-span-8 py-5 px-2">
          <Swiper
            effect={"cube"}
            grabCursor={true}
            cubeEffect={{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[EffectCube, Pagination, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img alt="drivein" src={Restaurant3} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="drivein" src={Restaurant4} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="drivein" src={Restaurant5} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="drivein" src={Restaurant6} />
            </SwiperSlide>{" "}
            <SwiperSlide>
              <img alt="drivein" src={Restaurant8} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="drivein" src={Restaurant7} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="drivein" src={Restaurant2} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="drivein" src={Restaurant1} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
